import isMobile from "is-mobile"
import { useState, useCallback, useEffect } from "react"
/* eslint-disable no-unused-vars */

const _navigator = typeof navigator === "object" ? navigator : {}
const ua = _navigator.userAgent || ""
const isTabletAgent = Boolean(
  ua.match(
    /iPad|Opera MiniTablet|Tablet|SM-T[0-9]+|KF[A-Z0-9]+|Nexus 9|Surface Pro|TB-[A-Z0-9]+|ASUS_Z[0-9]+D|A3-A[0-9]+/i
  )
)

export const BREAKPOINTS = {
  mobile: 0,
  mobileLg: 450,
  tablet: 768,
  tabletLg: 881,
  desktop: 1025,
}

// Helper function to check if the device is iPhone or Android (not a tablet)
export const useMobileDevice = () => {
  // Check if navigator and window are defined
  if (typeof navigator === "undefined" || typeof window === "undefined") return false;

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iOS detection (excluding iPads)
  // TODO: propably /iPhone/.test(userAgent) returns true on iOS PWA running on iPad
  const isIphone = /iPhone/.test(userAgent) && !window.MSStream;

  // Android detection, excluding tablets
  const isAndroid = /Android/.test(userAgent) && window.innerWidth < BREAKPOINTS.tablet;

  // PWA detection
  const isPwa = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

  // Return true if it's a mobile device or the app is running in a PWA on a mobile device
  return (isIphone || isAndroid) || (isPwa && (isIphone || isAndroid));
};


export const useTableDevice = () => {
  if (typeof navigator === "undefined" || typeof window === "undefined") return false; // Only run on client-side

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iPad detection
  // TODO: propably /iPad/.test(userAgent) returns false on iOS PWA running on iPad
  const isIpad = /iPad/.test(userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  // Android tablet detection (screen width greater than mobile breakpoint)
  const isAndroidTablet = /Android/.test(userAgent) && window.innerWidth >= BREAKPOINTS.tablet;

  // PWA detection
  const isPwa = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

  // Return true if it's a tablet device or the app is running in a PWA on a tablet
  return (isIpad || isAndroidTablet) || (isPwa && (isIpad || isAndroidTablet));
};

const useBreakpoints = () => {
  const [screen, setScreen] = useState(undefined)

  const onResize = useCallback(() => {
    const current = getScreen()
    setScreen(current)
  }, [])

  const getScreen = () => {
    const width = isMobile({ featureDetect: true, tablet: true })
      ? window.outerWidth
      : window.innerWidth

    // Adjust breakpoint logic
    if (width < BREAKPOINTS.mobileLg) {
      return "mobile"
    } else if (width >= BREAKPOINTS.mobileLg && width < BREAKPOINTS.tablet) {
      return "mobile-lg"
    } else if (width >= BREAKPOINTS.tablet && width < BREAKPOINTS.tabletLg) {
      return "tablet"
    } else if (width >= BREAKPOINTS.tabletLg && width < BREAKPOINTS.desktop) {
      return "tablet-lg"
    } else {
      return "desktop"
    }
  }

  useEffect(() => {
    onResize()

    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [onResize])

  return screen
}


export const useMobile = () => {
  const screen = useBreakpoints()

  return screen === "mobile" || screen === "mobile-lg"
}

export const useTablet = () => {
  const screen = useBreakpoints();

  return screen === "tablet-lg" || screen === "tablet"
};

export const useTabletLg = () => {
  const screen = useBreakpoints()

  return screen === "tablet-lg";
}

export const useDesktop = () => {
  const screen = useBreakpoints()

  return screen === "desktop";
}
