
const localStorageUtils = {
  setItem: (key, value, expiryInSeconds = null) => {
    try {
      const now = new Date();

      const item = expiryInSeconds
        ? {
            value: value,
            expiry: now.getTime() + expiryInSeconds * 1000,
          }
        : { value: value }; // No expiration

      window.localStorage.setItem(key, JSON.stringify(item));
    } catch (err) {
      console.error("Error setting item in localStorage: ", err);
    }
  },

  getItem: (key) => {
    try {
      const itemStr = window.localStorage.getItem(key);

      if (!itemStr) {
        return null;
      }

      const item = JSON.parse(itemStr);
      const now = new Date();

      if (item.expiry && now.getTime() > item.expiry) {
        window.localStorage.removeItem(key);
        return null;
      }

      return item.value;
    } catch (err) {
      console.error("Error getting item from localStorage: ", err);
      return null;
    }
  },

  removeItem: (key) => {
    try {
      window.localStorage.removeItem(key);
    } catch (err) {
      console.error("Error removing item from localStorage: ", err);
    }
  },
};

export default localStorageUtils;
