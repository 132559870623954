import * as firebaseApp from "firebase/app"
import * as firebaseMessaging from "firebase/messaging"

import getConfig from "next/config"
import { getProductDomain } from "./Product/ProductContext"
import { map } from "lodash/fp"
import { forEach } from "lodash"
import localStorageUtils from 'app/lib/hooks/localStorageUtils'

const { publicRuntimeConfig } = getConfig()

const VAPID_KEY = publicRuntimeConfig.FIREBASE_VAPID_KEY

export const FB_PN_TOKEN = "fb_pn_token"
export const EVENT_LIST = "event_notification_list"

let firebaseInitialized = false

// init firebase only one time to avoid fast refresh errors
const initFirebaseOnce = () => {
  if (!firebaseInitialized && !firebaseApp.getApps().length) {
    firebaseApp.initializeApp({
      apiKey: publicRuntimeConfig.FIREBASE_API_KEY,
      authDomain: publicRuntimeConfig.FIREBASE_AUTH_DOMAIN,
      databaseURL: publicRuntimeConfig.FIREBASE_DATABASE_URL,
      projectId: publicRuntimeConfig.FIREBASE_PROJECT_ID,
      storageBucket: publicRuntimeConfig.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: publicRuntimeConfig.FIREBASE_MESSAGING_SENDER_ID,
      appId: publicRuntimeConfig.FIREBASE_APP_ID,
      measurementId: publicRuntimeConfig.FIREBASE_MEASUREMENT_ID
    }, 'android')

    firebaseInitialized = true
  }
}

export const initPushNotifications = async (notificationFn) => {
  initFirebaseOnce()

  let firebaseAndroid = firebaseApp.getApp('android')
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers["push-subscribe"]
  ) {
    window.iOSPushCapability = true
  }

  if (await firebaseMessaging.isSupported()) {
    const messaging = firebaseMessaging.getMessaging(firebaseAndroid)

    const currentToken = await firebaseMessaging.getToken(messaging,{
      vapidKey: VAPID_KEY,
    })

    if (currentToken) {
      window.localStorage.setItem(FB_PN_TOKEN, currentToken)
    }

    const messageEventListenerHandler = (event) => {
      notificationFn(event.data)
    }

    navigator.serviceWorker.removeEventListener(
      "message",
      messageEventListenerHandler
    )
    navigator.serviceWorker.addEventListener(
      "message",
      messageEventListenerHandler
    )
  }
}

const iOSPushSubscribe = (topic, eventValue, unsubscribe) => {
  console.log("iOSPushSubscribe " + topic)
  window.webkit.messageHandlers["push-subscribe"].postMessage(
    JSON.stringify({
      topic: topic, // topic name to subscribe/unsubscribe
      eventValue, // user object: name, email, id, etc.
      unsubscribe, // true/false
    })
  )
}

const topicForEvent = (eventId) => {
  return [`Event-${eventId}`]
}

export const subscribeToInitialTopics = async (userId, orgId, eventList) => {
  const eventTopics = eventList?.map((event) => topicForEvent(event.id))?.flat() ?? []
  const generalTopics = [
    `User-${userId}`,
    `Alerts-${orgId}`,
    `Organization-${orgId}`,
  ]
  const allTopics = [...generalTopics, ...eventTopics] // spread operator

  window.localStorage.setItem(EVENT_LIST, JSON.stringify(allTopics))
  await Promise.all(map((topic) => subscribeToTopic(topic), allTopics))
}

export const updateEventSubscribeTopics = async (eventList) => {
  const newEventTopics = eventList?.map((event) => topicForEvent(event.id)).flat()

  // eslint-disable-next-line
  let oldEventTopics = await JSON.parse(window.localStorage.getItem(EVENT_LIST) ?? "[]")
  window.localStorage.removeItem(EVENT_LIST)

  newEventTopics.map((topic) => {
    const index = oldEventTopics?.indexOf(topic)

    if (index > -1) {
      const removeTopic = oldEventTopics?.splice(index, 1)[0]
      unsubscribeFromTopic(removeTopic)
    } else {
      subscribeToTopic(topic)
      oldEventTopics?.push(topic)
    }
  })

  window.localStorage.setItem(EVENT_LIST, JSON.stringify(oldEventTopics ?? []))
}

export const unsubscribeFromEventReminderTopics = async () => {
  console.log("unsubscribeFromEventReminderTopics")

  // eslint-disable-next-line
  const oldEventTopics = await JSON.parse(window.localStorage.getItem(EVENT_LIST) ?? "[]")

  window.localStorage.removeItem(EVENT_LIST)

  forEach(oldEventTopics, async (topic) => {
    if(topic.includes('Reminders')) {
      await unsubscribeFromTopic(topic)
    }
  })

  const updatedTopics = oldEventTopics.filter((topic) => topic.includes('Reminders') === false)

  window.localStorage.setItem(
    EVENT_LIST,
    JSON.stringify(updatedTopics ?? [])
  )
}

export const unsubscribeFromTopics = async (
  user,
  organization,
  eventList
) => {
  const topics = [
    `User-${user.id}`,
    `Alerts-${organization.id}`,
    `Organization-${organization.id}`,
  ]
  let eventTopics = []

  if (eventList) {
    // eventTopics = await eventList?.map((idEvent) => `Event-${idEvent}`)
    eventTopics = eventList?.map((event) => topicForEvent(event.id))?.flat() ?? []
  } else {
    eventTopics = await JSON.parse(window.localStorage.getItem(EVENT_LIST))
  }

  const allTopics = [topics, eventTopics].flat()
  await Promise.all(map(unsubscribeFromTopic, allTopics))
}

export const subscribeToTopic = async (topic) => {
  console.log("Subscribe to topic: " + topic)

  const productName = getProductDomain(window.location.hostname).productDomain

  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers["push-subscribe"]
  ) {
    window.iOSPushCapability = true
    iOSPushSubscribe(
      `${publicRuntimeConfig.FIREBASE_ENV}-${productName}-${topic}`,
      {},
      false
    )
  }

  const currentToken = window.localStorage.getItem(FB_PN_TOKEN)
  if (!currentToken) {
    return
  }

  return requestSubscribeToTopic(
    currentToken,
    `${publicRuntimeConfig.FIREBASE_ENV}-${productName}-${topic}`
  )
}

export const unsubscribeFromTopic = async (topic) => {
  console.log("Unsubscribe topic: " + topic)
  localStorageUtils.removeItem("TOPICS_ALREADY_SET")

  const productName = getProductDomain(window.location.hostname).productDomain

  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers["push-subscribe"]
  ) {
    window.iOSPushCapability = true
    iOSPushSubscribe(
      `${publicRuntimeConfig.FIREBASE_ENV}-${productName}-${topic}`,
      {},
      true
    )
  }

  const currentToken = window.localStorage.getItem(FB_PN_TOKEN)

  if (!currentToken)
    throw new Error(
      "No push notification token found. Make sure to call initPushNotifications first."
    )

  return requestUnsubscribeFromTopic(
    currentToken,
    `${publicRuntimeConfig.FIREBASE_ENV}-${productName}-${topic}`
  )
}

export const getFirebase = () => {
  initFirebaseOnce()
  return firebaseApp.getApp()
}

async function requestSubscribeToTopic(token, topic) {
  const response = await window.fetch(`/bots/api/firebase/subscribe?token=${encodeURIComponent(token)}&topic=${encodeURIComponent(topic)}`, {
    method: "POST",
  });

  const data = await response.json();
  if (response.status !== 200) {
    console.error('Error connecting to Firebase API: ', data);
  }

  return data;
}



async function requestUnsubscribeFromTopic(token, topic) {
  const response = await window.fetch(`/bots/api/firebase/unsubscribe?token=${encodeURIComponent(token)}&topic=${encodeURIComponent(topic)}`, {
    method: "POST",
  });

  const data = await response.json();
  if (response.status !== 200) {
    console.error('Error connecting to Firebase API: ', data);
  }

  return data;
}
