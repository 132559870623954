import {useEffect, useState} from 'react'
import {initPushNotifications, subscribeToInitialTopics, unsubscribeFromTopics,} from 'app/lib/PushNotificationService'
import localStorageUtils from 'app/lib/hooks/localStorageUtils'
import {useCurrentUser} from 'app/lib/CurrentUser'
import {useOrganization} from 'app/common/Organization'
import {useNotification} from 'app/features/notification/NotificationContext'
import Query from 'app/common/Query'
import {gql} from '@apollo/client'
import {getEventState} from 'app/features/events/EventUtils'
import moment from 'moment'

let PushNotificationsInitializer = () => null

export const QUERY = gql`
  query RegisteredEvents($startedFrom: Time!, $startedUntil: Time!) {
    currentUser {
      events(startedFrom: $startedFrom, startedUntil: $startedUntil){
        edges {
          node {
            id
            registered
            onWaitlist
          }
        }
      }
    }
  }
`

if (typeof window !== "undefined") {
  PushNotificationsInitializer = () => {
    const user = useCurrentUser()
    const organization = useOrganization()
    const { showNotification } = useNotification()
    const { getItem } = localStorageUtils;

    if (!organization || !user?.isAuthenticated) {
      return null
    }

    const topicsAlreadySet = getItem("TOPICS_ALREADY_SET")
    if (topicsAlreadySet === user.id+"-"+organization.id ) {
      return null
    }

    return (
      <Query
        query={QUERY}
        variables={{
          startedFrom: moment().subtract(1, "days").format(),
          startedUntil: moment().add(15, "days").format(),
        }}
        path={"organization.events"}
        fetchPolicy="cache-first"
      >
        {({data}) => {
          if (!data || !data.currentUser || !data.currentUser.events) return null

          const events = data.currentUser.events?.edges

          const eventList = events?.map((item) => ({
            "id": item.node.id,
            "state": getEventState(item)
          }))

          return (
            <NotificationsInitializer
              user={user}
              organization={organization}
              eventList={eventList}
              showNotification={showNotification}
            />
          )
        }}
      </Query>
    )
  }

  const NotificationsInitializer = ({
    user,
    organization,
    eventList,
    showNotification,
  }) => {
    const [orgId, setOrgId] = useState()

    useEffect(() => {
      window.removeEventListener("push-notification", (push) =>
        showNotification({ notification: push.detail })
      )
      window.addEventListener("push-notification", (push) =>
        showNotification({ notification: push.detail })
      )
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const firebasePushNotificationsInitializer = async () => {
        if (
          user.isAuthenticated &&
          (organization?.isChildless ?? false) &&
          organization.id !== orgId
        ) {
          if (orgId) {
            await unsubscribeFromTopics(user.id, orgId, eventList)
          }

          setOrgId(organization.id)
          await initPushNotifications(showNotification)
          await subscribeToInitialTopics(user.id, organization.id, eventList)
          localStorageUtils.setItem("TOPICS_ALREADY_SET", user.id+"-"+organization.id ,60*10)
        }
      }

      return firebasePushNotificationsInitializer().catch((error) => {
        console.error("Unable to initialize Firebase", error)
        localStorageUtils.removeItem("TOPICS_ALREADY_SET")
      })
    }, [user, organization, eventList, orgId, showNotification])

    return null
  }
}

export default PushNotificationsInitializer
